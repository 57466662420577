
/*@font-face {*/
/*    font-family: 'Encode Sans';*/
/*    src: url("./public/fonts/pf_tempesta_seven_condensed.eot") !* EOT file for IE *!*/
/*}*/
@font-face {
    font-family: 'Encode Sans';
    font-weight: normal;
    src: url("/src/assets/fonts/EncodeSans-Regular.ttf") /* TTF file for CSS3 browsers */
}
@font-face {
    font-family: 'Encode Sans';
    font-weight: bold;
    src: url("/src/assets/fonts/EncodeSans-Bold.ttf") /* TTF file for CSS3 browsers */
}
@font-face {
    font-family: 'Encode Sans';
    font-weight: 300;
    src: url("/src/assets/fonts/EncodeSans-Light.ttf") /* TTF file for CSS3 browsers */
}
@font-face {
    font-family: 'Encode Sans';
    font-weight: 200;
    src: url("/src/assets/fonts/EncodeSans-Thin.ttf") /* TTF file for CSS3 browsers */
}

body {
    /*overflow: hidden;*/
    margin:0;
    padding:0;
    background-color:#181e34;
    background:'linear-gradient(120deg,#181e35 35%,#10192a)';
    color:#fff;
    font-family: Encode Sans, sans-serif;
}

a, a:active, a:visited, a:hover, a:link {
    font-weight: 600;
    color: #8F9FCA;
    text-decoration: none;
}

input[type="text"], input[type="email"], input[type="password"], select, textarea {
    background-color: transparent;
    color: #8e929e;
    /*height: 50px;*/
    padding: 15px 0 15px 10px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 25px;
    font-family: Encode Sans,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
    outline: none;
}

h1 {
    margin-top: 0;
}

select {
    height: 57px;
}

textarea {
    height: 400px;
}

button, a.btn {
    background: linear-gradient(169.8deg, #ff982e, #f92d3c);
    border-radius: 25px;
    border: 0;
    width: 100%;
    height: 40px;
    color: #fff;
    font-family: Encode Sans,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 13px 32px;
}

#top a {
    padding:26px 32px;
    color: #fff;
    font-size: 14px;
}
#top .active {
    border-bottom: 3px solid #fb5836;
}

.top {
    height: 40px;
    padding: 20px;
    background: linear-gradient(100deg,#111628 35%,#0f1222);
}

.cursor {
    cursor: pointer;
}
